class UsersServiceClass {
    public socket: any; 
    private namespace: string;
    constructor(_socket,_namespace) {
        this.socket = _socket; 
        this.namespace = _namespace
    }
    getRolesPageService(query: string,projection:any, top: number, skip: number, orderby, reverse: boolean, collectionname: string,
        isMongoQuery: boolean = false) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to ");
            }
            try {
                this.socket.emit("GET_ROLES_PAGE", query,projection, top, skip, orderby, reverse, collectionname, isMongoQuery,
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }  
    AddUser(item:any, collectionname:string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADDUSER', item, collectionname, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    UpdateUser(item:any, collectionname:string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('UPDATEUSER', item, collectionname, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

    AddUserRole(roleItem:any ) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADDUSERROLE', roleItem  , function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    settoken(jwt:string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('AUTHJWT', jwt, function (err, token, user) {
                    if(err) { return reject(err); }
                    user.token = token;
                    resolve(user);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    isSuperAdmin(username:string, password:string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('IS_USER_SUPERADMIN', username, password, function (err, res) {
                    if(err) { return reject(err); }
               
                    resolve(res);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    auth(username:string, password:string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('AUTH', username, password, function (err, token, user) {
                    if(err) { return reject(err); }
                    user.token = token;
                    resolve(user);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    GetUserToken(username:string, password:string,_id :string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('GETUSERTOKEN', username, password , _id, function (err,token, user) {
                    if(err) { return reject(err); }

                    user.token = token;
                    resolve(user);
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    hash(password:string) {
        return new Promise<string>(async (resolve, reject) => {
            try {
                this.socket.emit('HASH', password, function (err, result) {
                    if(err) { return reject(err); }
                    resolve(result);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    recoverpassword(username:string) {
        return new Promise(async (resolve, reject) => {
            try {
                this.socket.emit('RECOVEREPASSWORD', username, function (err) {
                    if(err) { return reject(err); }
                    resolve("");
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    resetpassword(token:string, newpassword:string) {
        return new Promise(async (resolve, reject) => {
            try {
                this.socket.emit('RESETPASSWORD', token, newpassword, function (err) {
                    if(err) { return reject(err); }
                    resolve("");
                });
            } catch (err) {
                reject(err);
            }
        });
    }
    signout() {
        return new Promise(async (resolve, reject) => {
            try {
                this.socket.emit('SIGNOUT', function (err) {
                    if(err) { return reject(err); }
                    resolve("");
                });
            } catch (err) {
                reject(err);
            }
        });
    }

    

}